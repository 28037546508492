<template>
	<div>
		<div v-if="readonly" style="padding: 2px 0 0 1px">
			<v-icon size="18">{{ !new_val ? "mdi-checkbox-blank-outline": "mdi-checkbox-marked" }}</v-icon>
		</div>
		<div style="position: relative; left: -4px; top: -2px; width: 100%;" class="mx-0 cursor-pointer" @click.stop="checkVal()" v-else>
			<v-row v-if="labelTop" :justify="labelTop ? 'center':'start'" class="mx-0" style="position: absolute; width: 100%; top: -10px;">
				<Label background="var(--v-cell-base)" :value="labelTop" :labelTop="true" @click="new_val = !new_val" />
			</v-row>
			<v-row align="center" :justify="labelTop ? 'center':'start'" no-gutters class="flex-nowrap text-truncate">
				<v-col cols="auto">
					<v-btn icon small>
						<v-icon :color="new_val ? activeColor : ''" size="18">{{ intermediate ? 'mdi-minus-box' : !new_val ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked' }}</v-icon>
					</v-btn>
				</v-col>
				<v-col class="text-truncate">
					<div
							v-if="label"
							class="pl-1 paragraph text-truncate"
					>
						{{ label.display_name ? $options.filters.firstCapital(label.display_name) : $options.filters.firstCapital(label) }}
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CheckBox",
		props: {
			value: {type: Boolean},
			labelTop: {},
			label: {},
			activeColor: {default: 'labelprimary'},
			intermediate: {type: Boolean},
			readonly: {type: Boolean}
		},
		components: {
			Label: () => import('@/components/ui/Label.vue')
		},
		computed: {
			new_val: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		},
		methods: {
			checkVal(){
				if(typeof this.new_val === 'undefined') {
					this.$emit('input', true)
				}
				else if(this.new_val === true){
					this.$emit('input', false)
				}
				else {
					this.$emit('input', true)
					this.$emit('intermediate')
				}
			}
		},
		mounted() {
			if(!this.intermediate && typeof this.value === 'undefined'){
				this.$emit('input', false)
			}
		}
	}
</script>

<style scoped>

</style>
